import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixModal from '@1024pix/pix-ui/components/pix-modal';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import dayjs from 'dayjs';
import { t } from 'ember-intl';
import { formatPercentage } from 'pix-certif/helpers/format-percentage';
import CandidateDetailsModalRow from './candidate-details-modal-row';
const TRANSLATE_PREFIX = 'pages.sessions.detail.candidates';
const FIELDS = [
    {
        label: 'labels.candidate.birth-name',
        value: 'lastName'
    },
    {
        label: 'labels.candidate.firstname',
        value: 'firstName'
    },
    {
        label: 'labels.candidate.birth-date',
        value: 'birthdate',
        transform: (value1)=>(value1 ? dayjs(value1).format('DD/MM/YYYY') : undefined)
    },
    {
        label: 'labels.candidate.gender.title',
        value: 'genderLabel'
    },
    {
        label: 'labels.candidate.birth-city',
        value: 'birthCity'
    },
    {
        label: 'labels.candidate.birth-city-postcode',
        value: 'birthPostalCode'
    },
    {
        label: 'labels.candidate.birth-city-insee-code',
        value: 'birthInseeCode'
    },
    {
        label: 'labels.candidate.birth-country',
        value: 'birthCountry'
    },
    {
        label: 'forms.certification-labels.email-results',
        value: 'resultRecipientEmail'
    },
    {
        label: 'forms.certification-labels.email-convocation',
        value: 'email'
    },
    {
        label: 'forms.certification-labels.external-id',
        value: 'externalId'
    },
    {
        label: 'forms.certification-labels.extratime-percentage',
        value: 'extraTimePercentage',
        transform: (value1)=>(value1 ? formatPercentage([
                value1
            ]) : undefined)
    }
];
export default class CandidateDetailsModal extends Component {
    @service
    intl;
    @action
    getRowLabel(label1) {
        return this.intl.t(`common.${label1}`);
    }
    @action
    getRowValue(key1, transform1 = ()=>{}) {
        const value1 = this.args.candidate[key1];
        return transform1(value1) || value1 || '-';
    }
    computeSubscriptionsText = (candidate1)=>{
        const complementaryCertificationList1 = this.args.complementaryCertifications ?? [];
        const subscriptionLabels1 = [];
        if (candidate1.hasDualCertificationSubscriptionCoreClea(complementaryCertificationList1)) {
            subscriptionLabels1.push(this.intl.t(`${TRANSLATE_PREFIX}.list.subscriptions.dual-core-clea`));
        } else {
            for (const subscription1 of candidate1.subscriptions){
                if (subscription1.isCore) subscriptionLabels1.unshift(this.intl.t(`${TRANSLATE_PREFIX}.list.subscriptions.core`));
                else {
                    const candidateComplementaryCertification1 = complementaryCertificationList1.find((complementaryCertification1)=>complementaryCertification1.id === subscription1.complementaryCertificationId);
                    subscriptionLabels1.push(candidateComplementaryCertification1?.label || '-');
                }
            }
        }
        return subscriptionLabels1.join(', ');
    };
    static{
        template(`
    <PixModal
      @title={{t 'pages.sessions.detail.candidates.detail-modal.title'}}
      @onCloseButtonClick={{@closeModal}}
      class='certification-candidate-details-modal'
      @showModal={{@showModal}}
    >
      <:content>
        <ul class='certification-candidate-details-modal__list'>
          {{#each FIELDS as |field|}}
            <CandidateDetailsModalRow
              @label={{this.getRowLabel field.label}}
              @value={{this.getRowValue field.value field.transform}}
            />
          {{/each}}
          {{#if @shouldDisplayPaymentOptions}}
            <CandidateDetailsModalRow
              @label={{this.getRowLabel 'forms.certification-labels.pricing'}}
              @value={{this.getRowValue 'billingModeLabel'}}
            />
            <CandidateDetailsModalRow
              @label={{this.getRowLabel 'forms.certification-labels.prepayment-code'}}
              @value={{this.getRowValue 'prepaymentCode'}}
            />
          {{/if}}
          <CandidateDetailsModalRow
            @label={{t 'common.forms.certification-labels.selected-subscriptions'}}
            @value={{this.computeSubscriptionsText @candidate}}
          />
        </ul>
      </:content>

      <:footer>
        <PixButton
          @triggerAction={{@closeModal}}
          aria-label='{{t "pages.sessions.detail.candidates.detail-modal.actions.close-extra-information"}}'
        >{{t 'common.actions.close'}}</PixButton>
      </:footer>
    </PixModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
